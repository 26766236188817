import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';

export const emailRegExp = new RegExp(/[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,}/);

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    const isValid = emailRegExp.test(control.value);
    return isValid || !control.value ? null : { email: { value: control.value } };
  };
}

export function atLeastOneTrueValidator(formArray: FormArray): ValidationErrors | null {
  const hasTrueElement = formArray.controls.some((control: AbstractControl) => control.value === true);
  return hasTrueElement ? null : { atLeastOneTrue: true };
}

export function isFutureDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date = new Date(control.value);
    const now = new Date();

    return date <= now ? { isFutureDate: true } : null;
  };
}
